import Dashboard from "views/Dashboard.js";
import Client from "layouts/Client.js";
import Clinic from "layouts/Clinic.js";
import Exam from "layouts/Exam.js";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Inicio",
    icon: "nc-icon nc-chart-pie-35",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/client",
    name: "Paciente",
    icon: "nc-icon nc-single-02",
    component: Client,
    layout: "/admin",
  },
  {
    path: "/clinic",
    name: "Clinica",
    icon: "nc-icon nc-ambulance",
    component: Clinic,
    layout: "/admin",
  },
  {
    path: "/exam",
    name: "Exame",
    icon: "nc-icon nc-notes",
    component: Exam,
    layout: "/admin",
  },
];

export default dashboardRoutes;
