import api from "client/api";
import React, { useEffect, useState } from "react";
import * as moment from "moment";
import { Paginator } from "../helpers/paginator";
import useReactRouter from "use-react-router";
import {
  Form,
  Card,
  Table,
  Container,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import { onChangeFunc } from "helpers/onChangeFunc";
import PreLoader from "../components/Loader/Loader";
import DefaultAlert from "../components/Alert/Alert";

function TableList() {
  const [user, setUser] = useState([]);
  const [search, setSearch] = useState("");
  const [exams, setExams] = useState([]);
  const [pageInfos, setPageInfos] = useState({ page: 1, pageTotal: 1 });
  const { history } = useReactRouter();
  const [dateValue, setDateValue] = useState({});
  const [preLoad, setPreLoad] = useState(true);
  const [message, setMessage] = useState("");
  const [hidden, setHidden] = useState(true);

  const getUserExams = async (page = 1, searchTerm = "", date = {}) => {
    setPreLoad(true);
    let query = `page=${page}&search=${searchTerm}`;

    if (date || Object.entries(date).length > 1) {
      const end = moment(date.end).add(1, "d").format("YYYY-MM-DD");
      query = `${query}&date_start=${date.start}&date_end=${end}`;
    }

    const {
      data: { data, ...pageInfos },
      response,
    } = await api.get(`/exams?${query}`);

    if (response) {
      setMessage(response.data.message);
      setHidden(false);
      window.setTimeout(() => {
        setPreLoad(false);
        setHidden(true);
      }, 4000);
      return;
    }

    setPreLoad(false);
    setSearch(searchTerm);
    setExams(data);
    setPageInfos(pageInfos);
  };

  const deleteExam = async (id) => {
    const { response } = await api.delete(`/exams/${id}`);
    if (response) {
      setMessage(response.data.message);
      setHidden(false);
      window.setTimeout(() => {
        setHidden(true);
      }, 4000);
      return;
    }

    await getUserExams(pageInfos.page, search, dateValue);
  };

  useEffect(() => {
    getUserExams(pageInfos.page, search);
  }, []);

  const renderLink = (url) => {
    if (url) {
      return (
        <a href={url} target="_blank">
          <Button className="btn-fill pull-right" variant="info">
            <i class="fas fa-file-download"></i>
          </Button>{" "}
        </a>
      );
    }

    return (
      <>
        <Button
          className="btn-fill pull-right"
          variant="default"
          disabled="true"
        >
          <i class="fas fa-file-download"></i>
        </Button>{" "}
      </>
    );
  };

  return (
    <>
      <Container fluid>
        <PreLoader hidden={!preLoad} />
        <DefaultAlert message={message} hidden={hidden} variant="danger" />
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Row className="d-flex justify-content-between">
                  <Col sm="9">
                    <Card.Title as="h4">Lista de Exames</Card.Title>
                    <p className="card-category">{user.rx_code || ""}</p>
                  </Col>
                </Row>
                <Row className="d-flex align-items-center">
                  <Col md="3" sm="5">
                    <Form.Group>
                      <Form.Label>Buscar</Form.Label>
                      <Form.Control
                        placeholder="Buscar"
                        onChange={(e) =>
                          getUserExams(pageInfos.page, e.target.value)
                        }
                        name="search"
                        type="text"
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md="3" sm="3">
                    <Form.Group>
                      <Form.Label>Data Inicial</Form.Label>
                      <Form.Control
                        class="form-control"
                        onChange={(e) =>
                          onChangeFunc(e, dateValue, setDateValue)
                        }
                        type="date"
                        name="start"
                        value={dateValue.start}
                        id="example-date-input"
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md="3" sm="3">
                    <Form.Group>
                      <Form.Label>Data Final</Form.Label>
                      <Form.Control
                        class="form-control"
                        onChange={(e) =>
                          onChangeFunc(e, dateValue, setDateValue)
                        }
                        name="end"
                        type="date"
                        value={dateValue.end}
                        id="example-date-input"
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md="2" sm="2">
                    <Button
                      className="btn-fill pull-right"
                      variant="success"
                      size="md"
                      onClick={() =>
                        getUserExams(pageInfos.page, search, dateValue)
                      }
                    >
                      <i class="fas fa-search"></i>
                    </Button>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table className="table-hover table-striped">
                  <thead>
                    <tr>
                      <th className="border-0">Paciente</th>
                      <th className="border-0">Status</th>
                      <th className="border-0">Tipo do exame</th>
                      <th className="border-0">veterinário</th>
                      <th className="border-0">Dt. Criação</th>
                      <th className="border-0"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {exams.map((exam) => (
                      <tr>
                        <td>{exam.name}</td>
                        <td>
                          <div
                            className={
                              exam.status == "Concluído"
                                ? "status status-done"
                                : "status status-pending"
                            }
                          >
                            {exam.status}
                          </div>
                        </td>
                        <td>{exam.exam}</td>
                        <td>{exam.veterinarian}</td>
                        <td>{moment(exam.created_at).format("DD/MM/YYYY")}</td>
                        <td>
                          {renderLink(exam.url)}
                          <Button
                            className="btn-fill pull-right"
                            variant="primary"
                            onClick={() =>
                              history.push(
                                `/admin/exam/view/${exam.exam_id}/${exam.user_id}`
                              )
                            }
                          >
                            <i class="fas fa-eye"></i>
                          </Button>{" "}
                          <Button
                            className="btn-fill pull-right"
                            variant="warning"
                            onClick={() =>
                              history.push(
                                `/admin/exam/edit/${exam.exam_id}/${exam.user_id}`
                              )
                            }
                          >
                            <i class="fas fa-edit"></i>
                          </Button>{" "}
                          <Button
                            className="btn-fill pull-right"
                            variant="success"
                            onClick={() =>
                              history.push(
                                `/admin/exam/upload/${exam.exam_id}/${exam.user_id}`
                              )
                            }
                          >
                            <i class="fas fa-file-upload"></i>
                          </Button>{" "}
                          <Button
                            className="btn-fill pull-right"
                            variant="danger"
                            onClick={() => deleteExam(exam.exam_id)}
                          >
                            <i class="fas fa-trash"></i>
                          </Button>{" "}
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colspan="100%">
                        <div className="d-flex justify-content-center">
                          <Paginator
                            page={pageInfos.page}
                            pageTotal={pageInfos.pageTotal}
                            search={search}
                            listFunc={getUserExams}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default TableList;
