import CreateClinic from "views/CreateClinic.js";
import EditClinic from "views/EditClinic.js";
import ListClinics from "views/ListClinics.js";
import ViewClinic from "views/ViewClinic.js";

const dashboardRoutes = [
  {
    path: "/clinic/create",
    component: CreateClinic,
    layout: "/admin",
  },
  {
    path: "/clinic/edit/:clinic_id",
    component: EditClinic,
    layout: "/admin",
  },
  {
    path: "/clinic/view/:clinic_id",
    component: ViewClinic,
    layout: "/admin",
  },
  {
    path: "/clinic",
    component: ListClinics,
    layout: "/admin",
  },
];

export default dashboardRoutes;
