import React from "react";
import { Alert } from "react-bootstrap";

export default function DefaultAlert({ variant, message, hidden }) {
  return (
    <Alert className="alert-class" variant={variant} hidden={hidden}>
      {message}
    </Alert>
  );
}
