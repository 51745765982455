import React, { useEffect, useState } from "react";
import { onChangeFunc } from "helpers/onChangeFunc";
import useReactRouter from "use-react-router";
import { useParams } from "react-router-dom";
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import api from "client/api";
import Loader from "react-loader-spinner";
import PreLoader from "../components/Loader/Loader";
import DefaultAlert from "../components/Alert/Alert";

function User({ disable = false, title = "Editar" }) {
  const [user, setUser] = useState({});
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [preLoad, setPreLoad] = useState(true);
  const [message, setMessage] = useState("");
  const [hidden, setHidden] = useState(true);

  const { user_id } = useParams();
  const { history } = useReactRouter();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const editUser = async () => {
    setLoader(true);
    const dataFormatted = {
      user: {
        name: user.name,
        contact: user.contact,
      },
      userComplement: {
        other_name: user.other_name,
        specie: user.specie,
        race: user.race,
        age: user.age,
        gender: user.gender,
        is_castrated: user.is_castrated,
        age_type: user.age_type,
      },
    };

    const { data, response } = await api.put(
      `/users/client/${user_id}`,
      dataFormatted
    );

    if (response) {
      setMessage(response.data.message);
      setHidden(false);
      setLoader(false);
      window.setTimeout(() => {
        setHidden(true);
      }, 4000);
      return;
    }

    setLoader(false);
    handlerToFinish();
  };

  const getUser = async () => {
    const { data, response } = await api.get(`/users/client/${user_id}`);

    if (response) {
      setMessage(response.data.message);
      setHidden(false);
      setPreLoad(false);
      window.setTimeout(() => {
        setHidden(true);
      }, 4000);
      return;
    }

    setPreLoad(false);
    setUser(data);
  };

  useEffect(() => {
    getUser();
  }, []);

  const handlerToFinish = () => {
    handleClose();
    history.push(`/admin/exam/${user_id}`);
  };

  const renderButton = () => {
    if (loader) {
      return (
        <>
          <Button className="btn-fill pull-right" variant="info" disabled>
            <Loader type="Oval" color="#000" height={20} width={20} />
          </Button>{" "}
        </>
      );
    }

    return (
      <>
        <Button
          className="btn-fill pull-right"
          onClick={editUser}
          variant="info"
        >
          Editar paciente
        </Button>{" "}
      </>
    );
  };

  const renderButtonEdit = () => {
    if (!disable) {
      return <>{renderButton()}</>;
    }
  };

  return (
    <>
      <Container fluid>
        <PreLoader hidden={!preLoad} />
        <DefaultAlert message={message} hidden={hidden} variant="danger" />
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h4">
                  {title} paciente {user.name}
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Form>
                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>Paciente</label>
                        <Form.Control
                          placeholder="Paciente"
                          value={user.name}
                          onChange={(e) => onChangeFunc(e, user, setUser)}
                          name="name"
                          type="text"
                          disabled={disable}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="px-1" md="6">
                      <Form.Group>
                        <label>Tutor</label>
                        <Form.Control
                          placeholder="Tutor"
                          value={user.other_name}
                          onChange={(e) => onChangeFunc(e, user, setUser)}
                          name="other_name"
                          type="text"
                          disabled={disable}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>Contato</label>
                        <Form.Control
                          placeholder="Contato"
                          value={user.contact || ""}
                          onChange={(e) => onChangeFunc(e, user, setUser)}
                          name="contact"
                          type="text"
                          disabled={disable}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="px-1" md="2">
                      <Form.Group>
                        <label>Idade</label>
                        <Form.Control
                          placeholder="Idade"
                          value={user.age}
                          onChange={(e) => onChangeFunc(e, user, setUser)}
                          name="age"
                          type="text"
                          disabled={disable}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="px-1" md="2">
                      <Form.Group>
                        <label>Tempo</label>
                        <Form.Control
                          as="select"
                          onChange={(e) => onChangeFunc(e, user, setUser)}
                          name="age_type"
                          type="text"
                          disabled={disable}
                        >
                          <option value="">Selecione</option>
                          <option
                            selected={user.age_type == "ano(s)" ? true : false}
                            value="ano(s)"
                          >
                            Anos(s)
                          </option>
                          <option
                            selected={user.age_type == "dia(s)" ? true : false}
                            value="dia(s)"
                          >
                            Dia(s)
                          </option>
                          <option
                            selected={user.age_type == "mês(es)" ? true : false}
                            value="mês(es)"
                          >
                            Mês(es)
                          </option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="px-1" md="2">
                      <Form.Group>
                        <label>Castrado</label>
                        <Form.Control
                          as="select"
                          onChange={(e) => onChangeFunc(e, user, setUser)}
                          name="is_castrated"
                          type="text"
                          disabled={disable}
                        >
                          <option value="">Selecione</option>
                          <option
                            selected={user.is_castrated ? true : false}
                            value="1"
                          >
                            Sim
                          </option>
                          <option
                            selected={user.is_castrated ? false : true}
                            value="0"
                          >
                            Não
                          </option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* {modal()} */}
                  <Row>
                    <Col className="pr-1" md="4">
                      <Form.Group>
                        <label>Raça</label>
                        <Form.Control
                          placeholder="Raça"
                          value={user.race}
                          onChange={(e) => onChangeFunc(e, user, setUser)}
                          name="race"
                          type="text"
                          disabled={disable}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="4">
                      <Form.Group>
                        <label>Espécie</label>
                        <Form.Control
                          placeholder="Espécie"
                          value={user.specie}
                          onChange={(e) => onChangeFunc(e, user, setUser)}
                          name="specie"
                          type="text"
                          disabled={disable}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="4">
                      <Form.Group>
                        <label>Genero</label>
                        <Form.Control
                          as="select"
                          onChange={(e) => onChangeFunc(e, user, setUser)}
                          name="gender"
                          type="text"
                          disabled={disable}
                        >
                          <option value="">Selecione</option>
                          <option
                            selected={user.gender == "Fêmea" ? true : false}
                            value="Fêmea"
                          >
                            Fêmea
                          </option>
                          <option
                            selected={user.gender == "Macho" ? true : false}
                            value="Macho"
                          >
                            Macho
                          </option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  {renderButtonEdit()}
                  <Button
                    className="btn-fill pull-right"
                    onClick={() => history.goBack()}
                    variant="secondary"
                  >
                    Voltar
                  </Button>
                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default User;
