import axios from "axios";

const URL_API =
  process.env.REACT_APP_ENV === "develop"
    ? process.env.REACT_APP_API_URL_DEV
    : process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL: URL_API,
});

console.log("URL_API", URL_API);

api.interceptors.request.use(function (config) {
  const token = window.localStorage.getItem("token");
  const role = window.localStorage.getItem("role");
  config.headers.Authorization = "Bearer " + token;
  config.headers.role = role;

  // Add the necessary headers for CORS
  config.headers["Access-Control-Allow-Origin"] = "*";
  config.headers["Access-Control-Allow-Methods"] = "GET, POST, PUT, DELETE";
  config.headers["Access-Control-Allow-Headers"] =
    "Content-Type, Authorization";

  return config;
});

api.interceptors.response.use(
  (res) => res,
  function (error) {
    console.log("errro", error);
    // if (error.response.status === 401 || error.response.status === 403) {
    //   window.localStorage.setItem("access_token", "");
    //   window.location.href = `/login?error=true&status=${error.response.status}`;
    // }
    return error;
  }
);

export default api;
