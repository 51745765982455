import CreateExam from "views/CreateExam.js";
import EditExam from "views/EditExam.js";
import Upload from "views/Upload.js";
import ListExamByUser from "views/ListExamByUser";
import ListExam from "views/ListExam";
import ViewExam from "views/ViewExam";

const dashboardRoutes = [
  {
    path: "/exam/edit/:exam_id/:user_id",
    component: EditExam,
    layout: "/admin",
  },
  {
    path: "/exam/upload/:exam_id/:user_id",
    component: Upload,
    layout: "/admin",
  },
  {
    path: "/exam/create/:user_id",
    component: CreateExam,
    layout: "/admin",
  },
  {
    path: "/exam/view/:exam_id/:user_id",
    component: ViewExam,
    layout: "/admin",
  },
  {
    path: "/exam/:user_id",
    component: ListExamByUser,
    layout: "/admin",
  },
  {
    path: "/exam",
    component: ListExam,
    layout: "/admin",
  },
];

export default dashboardRoutes;
