import React, { useEffect, useState } from "react";
import { onChangeFunc } from "helpers/onChangeFunc";
import { useParams } from "react-router-dom";
import useReactRouter from "use-react-router";
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Modal,
} from "react-bootstrap";
import api from "client/api";
import Loader from "react-loader-spinner";
import PreLoader from "../components/Loader/Loader";
import DefaultAlert from "../components/Alert/Alert";

function User({ disable = false, title = "Editar" }) {
  const { exam_id, user_id } = useParams();
  const { history } = useReactRouter();

  const [fieldDisable, setFieldDisable] = useState(false);
  const [exam, setExam] = useState({});
  const [status, setStatus] = useState({});
  const [rxCode, setRxCode] = useState(``);
  const [types, setTypes] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [show, setShow] = useState(false);
  const [clinic, setClinic] = useState({ id: "", value: "" });
  const [clinics, setClinics] = useState([]);
  const [loader, setLoader] = useState(false);
  const [preLoad, setPreLoad] = useState(true);
  const [message, setMessage] = useState("");
  const [hidden, setHidden] = useState(true);

  const handleClose = () => {
    setClinics([]);
    setShow(false);
  };

  const handleShow = async () => {
    setShow(true);
  };

  const getClinics = async (search = "") => {
    const {
      data: { data },
    } = await api.get(`/users/clinics?search=${search}`);
    setClinics(data);
  };

  const getTypeAndStatus = async () => {
    const { data } = await api.get("/exams/status-types");
    setTypes(data.types);
    setStatus(data.status);
  };

  const getRxCode = async () => {
    const {
      data: { rx_code },
    } = await api.get(`/users/${user_id}/rx-code`);
    setRxCode({ rx_code: rx_code || "" });
    setDisabled(!!rx_code);
  };

  const getExam = async () => {
    const {
      data: { clinic, clinic_id, ...data },
    } = await api.get(`/exams/${exam_id}`);
    setExam(data);
    setPreLoad(false);
    setClinic({ id: clinic_id, value: clinic });
  };

  const onChangeExamType = (e, exam, setExam) => {
    if (e.target.value === "3231c780-05b4-4086-a0eb-e91e68c0f2cd") {
      setFieldDisable(true);
    } else {
      setFieldDisable(false);
    }
    onChangeFunc(e, exam, setExam);
  };

  const onKeyPressSearch = async (e) => {
    await getClinics(e.target.value);
  };

  const handlerToFinish = () => {
    history.push(`/admin/exam/${user_id}`);
  };

  const editExam = async () => {
    setLoader(true);
    const data = {
      veterinarian: exam.veterinarian,
      clinic_id: clinic.id,
      rx_code: rxCode.rx_code,
      exam_type_id: exam.exam_type_id,
      reason: exam.reason,
      description: exam.description,
      user_id,
    };
    const { response } = await api.put(`/exams/${exam_id}`, data);

    if (response) {
      setMessage(response.data.message);
      setHidden(false);
      window.setTimeout(() => {
        setHidden(true);
        setLoader(false);
      }, 4000);
      return;
    }

    setLoader(false);
    handlerToFinish();
  };

  useEffect(() => {
    getTypeAndStatus();
    getRxCode();
    getExam();
  }, []);

  const modal = () => {
    return (
      <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Body>
            <Row>
              <Col className="pr-1" md="12">
                <Form.Group>
                  <Form.Control
                    placeholder="Buscar clinica"
                    autoComplete="off"
                    onChange={onKeyPressSearch}
                    name="clinic"
                    type="text"
                  ></Form.Control>
                </Form.Group>
              </Col>
              <Col className="pr-1" md="12">
                {clinics.map((element) => (
                  <>
                    <div>
                      <Button
                        className="btn-fill pull-right  mr-3"
                        variant="info"
                        size="sm"
                        onClick={() => {
                          setClinic({ id: element.id, value: element.name });
                          handleClose();
                        }}
                      >
                        <i class="fas fa-plus-square"></i>
                      </Button>
                      {element.name}
                    </div>
                    <hr></hr>
                  </>
                ))}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  const renderButton = () => {
    if (loader) {
      return (
        <>
          <Button className="btn-fill pull-right" variant="info" disabled>
            <Loader type="Oval" color="#000" height={20} width={20} />
          </Button>{" "}
        </>
      );
    }

    return (
      <>
        <Button
          className="btn-fill pull-right"
          onClick={editExam}
          variant="info"
        >
          Editar exame
        </Button>{" "}
      </>
    );
  };

  const renderButtonEdit = () => {
    if (!disable) {
      return <>{renderButton()}</>;
    }
  };

  return (
    <>
      <Container fluid>
        <PreLoader hidden={!preLoad} />
        <DefaultAlert message={message} hidden={hidden} variant="danger" />
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h4">{title} exame</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form>
                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>Veterinário</label>
                        <Form.Control
                          placeholder="Veterinário"
                          onChange={(e) => onChangeFunc(e, exam, setExam)}
                          value={exam.veterinarian}
                          name="veterinarian"
                          type="text"
                          disabled={disable}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="px-1 d-flex flex-column" md="2">
                      {modal()}
                      <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label>Clinica</Form.Label>
                        <Form.Control
                          as="select"
                          type="text"
                          onClick={handleShow}
                          disabled={disable}
                        >
                          <option value={clinic.id || ""}>
                            {clinic.value || "Selecionar"}
                          </option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="2">
                      <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label>Tipo de exame</Form.Label>
                        <Form.Control
                          as="select"
                          onChange={(e) => onChangeExamType(e, exam, setExam)}
                          name="exam_type_id"
                          type="text"
                          disabled={disable}
                        >
                          <option value="">Selecione o tipo</option>
                          {types.map((type) => (
                            <option
                              selected={
                                type.id === exam.exam_type_id ? "true" : "false"
                              }
                              value={type.id}
                            >
                              {type.name}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="px-1" md="2" hidden={fieldDisable}>
                      <Form.Group>
                        <label>Cod. Raio X</label>
                        <Form.Control
                          placeholder=""
                          disabled={disable}
                          value={rxCode.rx_code || ""}
                          onChange={(e) => onChangeFunc(e, rxCode, setRxCode)}
                          name="rx_code"
                          type="text"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <Form.Group>
                        <label>Descrição</label>
                        <Form.Control
                          value={exam.description}
                          cols="80"
                          name="description"
                          onChange={(e) => onChangeFunc(e, exam, setExam)}
                          placeholder="Here can be your description"
                          rows="4"
                          as="textarea"
                          disabled={disable}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <Form.Group>
                        <label>Motivo</label>
                        <Form.Control
                          value={exam.reason}
                          cols="80"
                          name="reason"
                          onChange={(e) => onChangeFunc(e, exam, setExam)}
                          placeholder="Here can be your description"
                          rows="4"
                          as="textarea"
                          disabled={disable}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  {renderButtonEdit()}
                  <Button
                    className="btn-fill pull-right"
                    onClick={() => history.goBack()}
                    variant="secondary"
                  >
                    Voltar
                  </Button>
                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default User;
