import React, { useState } from "react";
import { onChangeFunc } from "helpers/onChangeFunc";
import useReactRouter from "use-react-router";
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Modal,
} from "react-bootstrap";
import api from "client/api";
import Loader from "react-loader-spinner";
import DefaultAlert from "../components/Alert/Alert";

function User() {
  const [user, setUser] = useState({});
  const [userId, setUserId] = useState("");
  const [show, setShow] = useState(false);
  const [access, setAccess] = useState({});
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState("");
  const [hidden, setHidden] = useState(true);
  const { history } = useReactRouter();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const createUser = async () => {
    setLoader(true);
    const dataFormatted = {
      user: {
        name: user.name,
        contact: user.contact,
      },
      userComplement: {
        other_name: user.other_name,
        specie: user.specie,
        race: user.race,
        age: user.age,
        gender: user.gender,
        is_castrated: user.is_castrated,
        age_type: user.age_type,
      },
    };

    const { data, response } = await api.post("/users", dataFormatted);

    if (response) {
      setMessage(response.data.message);
      setHidden(false);
      setLoader(false);
      window.setTimeout(() => {
        setHidden(true);
      }, 4000);
      return;
    }

    setAccess({ username: data.username, password: data.password });
    setUserId(data.user_id);
    setLoader(false);
    handleShow();
  };

  const handlerToFinish = () => {
    handleClose();
    history.push(`/admin/exam/${userId}`);
  };

  const modal = () => {
    return (
      <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Paciente registrado com sucesso</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Usuário: {access.username}</p>
            <p>Senha: {access.password}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handlerToFinish}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  const renderButton = () => {
    if (loader) {
      return (
        <>
          <Button
            className="btn-fill pull-right"
            onClick={createUser}
            variant="info"
            disabled
          >
            <Loader type="Oval" color="#000" height={20} width={20} />
          </Button>{" "}
        </>
      );
    }

    return (
      <>
        <Button
          className="btn-fill pull-right"
          onClick={createUser}
          variant="info"
        >
          Criar paciente
        </Button>{" "}
      </>
    );
  };

  return (
    <>
      <Container fluid>
        <DefaultAlert message={message} hidden={hidden} variant="danger" />
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Criar paciente</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form>
                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>Paciente</label>
                        <Form.Control
                          placeholder="Paciente"
                          onChange={(e) => onChangeFunc(e, user, setUser)}
                          name="name"
                          type="text"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="px-1" md="6">
                      <Form.Group>
                        <label>Tutor</label>
                        <Form.Control
                          placeholder="Tutor"
                          onChange={(e) => onChangeFunc(e, user, setUser)}
                          name="other_name"
                          type="text"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>Contato</label>
                        <Form.Control
                          placeholder="Contato"
                          onChange={(e) => onChangeFunc(e, user, setUser)}
                          name="contact"
                          type="text"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="px-1" md="2">
                      <Form.Group>
                        <label>Idade</label>
                        <Form.Control
                          placeholder="Idade"
                          onChange={(e) => onChangeFunc(e, user, setUser)}
                          name="age"
                          type="text"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="px-1" md="2">
                      <Form.Group>
                        <label>Tempo</label>
                        <Form.Control
                          as="select"
                          onChange={(e) => onChangeFunc(e, user, setUser)}
                          name="age_type"
                          type="text"
                        >
                          <option value="">Selecione</option>
                          <option value="ano(s)">Anos(s)</option>
                          <option value="dia(s)">Dia(s)</option>
                          <option value="mês(es)">Mês(es)</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="px-1" md="2">
                      <Form.Group>
                        <label>Castrado</label>
                        <Form.Control
                          as="select"
                          onChange={(e) => onChangeFunc(e, user, setUser)}
                          name="is_castrated"
                          type="text"
                        >
                          <option value="">Selecione</option>
                          <option value="1">Sim</option>
                          <option value="0">Não</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  {modal()}
                  <Row>
                    <Col className="pr-1" md="4">
                      <Form.Group>
                        <label>Raça</label>
                        <Form.Control
                          placeholder="Raça"
                          onChange={(e) => onChangeFunc(e, user, setUser)}
                          name="race"
                          type="text"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="4">
                      <Form.Group>
                        <label>Espécie</label>
                        <Form.Control
                          placeholder="Espécie"
                          onChange={(e) => onChangeFunc(e, user, setUser)}
                          name="specie"
                          type="text"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="pl-1" md="4">
                      <Form.Group>
                        <label>Genero</label>
                        <Form.Control
                          as="select"
                          onChange={(e) => onChangeFunc(e, user, setUser)}
                          name="gender"
                          type="text"
                        >
                          <option value="">Selecione</option>
                          <option value="Fêmea">Fêmea</option>
                          <option value="Macho">Macho</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  {renderButton()}
                  <Button
                    className="btn-fill pull-right"
                    onClick={() => history.goBack()}
                    variant="secondary"
                  >
                    Voltar
                  </Button>
                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default User;
