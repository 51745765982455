import api from "client/api";
import React, { useEffect, useState } from "react";
import useReactRouter from "use-react-router";
import Logo from "../../assets/img/cdi-logo.png";
import { Paginator } from "../../helpers/paginator";
import { onChangeFunc } from "../../helpers/onChangeFunc";
import * as moment from "moment";
import "./styles.css";
import { Modal, Button, Form, Col } from "react-bootstrap";
import Footer from "components/Footer/Footer";
import DefaultAlert from "../../components/Alert/Alert";

function ClinicList() {
  const [exams, setExams] = useState([]);
  const [pageInfos, setPageInfos] = useState({ page: 1, pageTotal: 1 });
  const [search, setSearch] = useState("");
  const [dateValue, setDateValue] = useState({
    start: moment().format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
  });
  const [pass, setPass] = useState({ password: "", confirmPass: null });
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [hidden, setHidden] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { history } = useReactRouter();

  const getUserExams = async (page = 1, searchTerm = "", date = null) => {
    let query = `page=${page}&search=${searchTerm}&pageSize=6`;

    if (date) {
      const end = moment(date.end).add(1, "d").format("YYYY-MM-DD");
      query = `${query}&date_start=${date.start}&date_end=${end}`;
    }

    const {
      data: { data: examsData, ...pageInfos },
    } = await api.get(`/exams/clinic?${query}`);
    setSearch(searchTerm);
    setExams(examsData);
    setPageInfos(pageInfos);
  };

  useEffect(() => {
    getUserExams(pageInfos.page, search);
  }, []);

  const savePass = async () => {
    const { password, confirmPass } = pass;
    if (password !== confirmPass) {
      setMessage("As senhas digitas devem ser iguais!");
      setHidden(false);
      window.setTimeout(() => {
        setHidden(true);
      }, 4000);
      return;
    }

    const { response } = await api.post("/users/reset-password", {
      password: pass.password,
    });

    if (response) {
      setMessage(response.data.message);
      setHidden(false);
      window.setTimeout(() => {
        setHidden(true);
      }, 4000);
      return;
    }

    handleClose();
  };

  const modal = () => {
    return (
      <>
        <Modal show={show} onHide={handleClose}>
          <DefaultAlert message={message} hidden={hidden} variant="danger" />
          <Modal.Header closeButton>
            <Modal.Title>Redefinição de senha</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Col className="pr-1" className="">
              <Form.Group>
                <Form.Control
                  placeholder="Digite sua nova senha"
                  autoComplete="off"
                  onChange={(e) => onChangeFunc(e, pass, setPass)}
                  name="password"
                  type="password"
                ></Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Control
                  placeholder="Digite sua nova senha novamente"
                  autoComplete="off"
                  onChange={(e) => onChangeFunc(e, pass, setPass)}
                  name="confirmPass"
                  type="password"
                ></Form.Control>
              </Form.Group>
            </Col>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fechar
            </Button>
            <Button variant="secondary" onClick={savePass}>
              Salvar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  const num = 1;
  if (num > 0) {
    return (
      <div className="container-list-clinic">
        <div className="container-listExams">
          <aside className="menu">
            <img src={Logo} width={150} height={100} alt="logo" />
            <div>
              <a
                onClick={() => history.push("/login")}
                style={{ color: "#677798", margin: 10 }}
              >
                Logout
              </a>
              <a onClick={handleShow} style={{ color: "#677798", margin: 10 }}>
                Redefinir Senha
              </a>
            </div>
          </aside>
          <div className="container-main">
            <header className="header-filter">
              <form className="form-filter">
                <input
                  className="input-filter"
                  type="text"
                  name="search"
                  placeholder="Paciente"
                  onChange={(e) => getUserExams(pageInfos.page, e.target.value)}
                />
                <input
                  className="input-filter"
                  type="date"
                  name="start"
                  onChange={(e) => onChangeFunc(e, dateValue, setDateValue)}
                  value={dateValue.start}
                />
                <input
                  className="input-filter"
                  type="date"
                  name="end"
                  onChange={(e) => onChangeFunc(e, dateValue, setDateValue)}
                  value={dateValue.end}
                />
                <button
                  className="button-filter"
                  type="button"
                  onClick={() =>
                    getUserExams(pageInfos.page, search, dateValue)
                  }
                >
                  Pesquisar
                </button>
              </form>
            </header>
            <main className="content-list">
              {modal()}
              {exams.map((exam) => (
                <div className="div-content">
                  <strong>Paciente:</strong>
                  <p>{exam.name}</p>
                  <strong>Tutor:</strong>
                  <p>{exam.owner}</p>
                  <strong>Veterinário(a):</strong>
                  <p>{exam.veterinarian}</p>
                  <strong>Tipo de Exame:</strong>
                  <p>{exam.exam}</p>
                  <strong>Data do Exame:</strong>
                  <p>{moment(exam.created_at).format("DD/MM/YYYY")}</p>
                  <a
                    className="download-exam"
                    href={exam.url}
                    target="_blank"
                    style={{
                      background:
                        exam.status == "Concluído" ? "#2B8B6B" : "#677798",
                    }}
                  >
                    {exam.status === "Concluído" ? (
                      <span>Baixar Exame</span>
                    ) : (
                      <span>Pendente</span>
                    )}
                  </a>
                </div>
              ))}
            </main>
            <div className="paginator-main">
              <Paginator
                page={pageInfos.page}
                pageTotal={pageInfos.pageTotal}
                search={search}
                listFunc={getUserExams}
              />
            </div>
            <a
              onClick={() => {
                window.scrollTo(0, 0);
              }}
              className="back-to-top"
            >
              <i
                className="fas fa-arrow-up fa-2x"
                style={{ marginRight: 10 }}
              ></i>
            </a>
          </div>
        </div>
        <Footer />
      </div>
    );
  } else {
    return (
      <div className="container-list-clinic">
        <div className="container-listExams">
          <div className="div-menu">
            <aside className="menu">
              <img src={Logo} width={150} height={100} alt="logo" />
              <div>
                <a
                  onClick={() => history.push("/login")}
                  style={{ color: "#677798", margin: 10 }}
                >
                  Logout
                </a>
                <a
                  onClick={handleShow}
                  style={{ color: "#677798", margin: 10 }}
                >
                  Redefinir Senha
                </a>
              </div>
            </aside>
          </div>
          <div className="container-main" style={{ alignSelf: "center" }}>
            <main className="content-list">
              {modal()}
              <h4 style={{ color: "#677798" }}>
                Este clinica ainda não possui pacientes.
              </h4>
            </main>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default ClinicList;
