import Loader from "react-loader-spinner";
import React from "react";

export default function PreLoader({ hidden }) {
  return (
    <div className="pre-load" hidden={hidden}>
      <Loader type="Oval" color="#000" height={100} width={100} />
    </div>
  );
}
