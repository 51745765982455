import React, { useEffect, useState } from "react";
import { onChangeFunc } from "helpers/onChangeFunc";
import { useParams } from "react-router-dom";
import Loader from "react-loader-spinner";
import DefaultAlert from "../components/Alert/Alert";
import useReactRouter from "use-react-router";
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Modal,
} from "react-bootstrap";
import api from "client/api";
import PreLoader from "../components/Loader/Loader";

function EditClinic({ disable = false, title = "Editar" }) {
  const [clinic, setClinic] = useState({});
  const [show, setShow] = useState(false);
  const [access, setAccess] = useState({});
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState("");
  const [hidden, setHidden] = useState(true);
  const [preLoad, setPreLoad] = useState(true);

  const { clinic_id } = useParams();

  const { history } = useReactRouter();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handlerToFinish = () => {
    history.push(`/admin/clinic/`);
  };

  const EditClinic = async () => {
    setLoader(true);
    const dataFormatted = {
      name: clinic.name,
      contact: clinic.contact,
      username: clinic.username,
    };

    const { response } = await api.put(
      "/users/clinic/" + clinic.id,
      dataFormatted
    );

    if (response) {
      setHidden(false);
      setMessage(response.data.message);
      setLoader(false);
      window.setTimeout(() => {
        setHidden(true);
      }, 4000);
      return;
    }

    setLoader(false);
    handlerToFinish();
  };

  const getClinic = async () => {
    const { data, response } = await api.get(`/users/clinic/${clinic_id}`);
    if (response) {
      setMessage(response.data.message);
      setHidden(false);
      window.setTimeout(() => {
        setPreLoad(false);
        setHidden(true);
      }, 4000);
      return;
    }
    setPreLoad(false);
    setClinic(data);
  };

  useEffect(() => {
    getClinic();
  }, []);

  const modal = () => {
    return (
      <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Senha atualizada com sucesso</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Usuário: {access.username}</p>
            <p>Senha: {access.password}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  const renderButton = () => {
    if (loader) {
      return (
        <>
          <Button className="btn-fill pull-right" variant="info" disabled>
            <Loader type="Oval" color="#000" height={20} width={20} />
          </Button>{" "}
        </>
      );
    }

    return (
      <>
        <Button
          className="btn-fill pull-right"
          onClick={EditClinic}
          variant="info"
        >
          Editar clinica
        </Button>{" "}
      </>
    );
  };

  const renderButtonEdit = () => {
    if (!disable) {
      return <>{renderButton()}</>;
    }
  };

  return (
    <>
      <Container fluid>
        <DefaultAlert message={message} hidden={hidden} variant="danger" />
        <PreLoader hidden={!preLoad} />
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h4">{title} clinica</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form>
                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <label>Clinica</label>
                        <Form.Control
                          placeholder="Clinica"
                          onChange={(e) => onChangeFunc(e, clinic, setClinic)}
                          name="name"
                          value={clinic.name || ""}
                          type="text"
                          disabled={disable}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="px-1" md="3">
                      <Form.Group>
                        <label>Contato</label>
                        <Form.Control
                          placeholder="Contato"
                          onChange={(e) => onChangeFunc(e, clinic, setClinic)}
                          name="contact"
                          value={clinic.contact || ""}
                          type="text"
                          disabled={disable}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="px-1" md="3">
                      <Form.Group>
                        <label>Usuário</label>
                        <Form.Control
                          placeholder="Usuário"
                          onChange={(e) => onChangeFunc(e, clinic, setClinic)}
                          name="username"
                          value={clinic.username || ""}
                          type="text"
                          disabled={disable}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  {modal()}
                  {renderButtonEdit()}
                  <Button
                    className="btn-fill pull-right"
                    onClick={() => history.goBack()}
                    variant="secondary"
                  >
                    Voltar
                  </Button>
                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default EditClinic;
