import React, { useState } from "react";
import { useParams } from "react-router-dom";
import useReactRouter from "use-react-router";
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import api from "client/api";
import Loader from "react-loader-spinner";

function User() {
  const { exam_id, user_id } = useParams();
  const { history } = useReactRouter();
  const [loader, setLoader] = useState(false);

  const [value, setValue] = useState({});

  const handlerToFinish = () => {
    history.push(`/admin/exam/${user_id}`);
  };

  const onChangeFile = ({ target: { name, files } }, values, setValues) => {
    setValues({ ...values, [name]: files[0] });
  };

  const uploadExam = async () => {
    setLoader(true);
    const formData = new FormData();

    formData.append("file", value.file);

    await api.put(`/exams/${exam_id}/user/${user_id}`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    setLoader(false);
    handlerToFinish();
  };

  const renderButton = () => {
    if (loader) {
      return (
        <>
          <Button className="btn-fill pull-right" variant="info" disabled>
            <Loader type="Oval" color="#000" height={20} width={20} />
          </Button>{" "}
        </>
      );
    }

    return (
      <>
        <Button
          className="btn-fill pull-right"
          onClick={uploadExam}
          size="md"
          variant="info"
        >
          Enviar exame
        </Button>{" "}
      </>
    );
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Anexar exame</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form>
                  <Row>
                    <Col className="pr-1" md="6">
                      <Form.Group>
                        <Form.Control
                          type="file"
                          className="form-control-file"
                          onChange={(e) => onChangeFile(e, value, setValue)}
                          name="file"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  {renderButton()}
                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default User;
