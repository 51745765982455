import api from "client/api";
import React, { useEffect, useState } from "react";
import useReactRouter from "use-react-router";
import Logo from "../../assets/img/cdi-logo.png";
import {} from "@fortawesome/fontawesome-free";
import * as moment from "moment";
import "./styles.css";
import Footer from "components/Footer/Footer";
import { Modal, Button, Form, Col } from "react-bootstrap";
import { onChangeFunc } from "helpers/onChangeFunc";
import DefaultAlert from "../../components/Alert/Alert";

function TableList() {
  const [user, setUser] = useState({
    complements: {
      other_name: "",
    },
  });
  const [exams, setExams] = useState([]);
  const [show, setShow] = useState(false);
  const [pass, setPass] = useState({ password: "", confirmPass: null });
  const [message, setMessage] = useState("");
  const [hidden, setHidden] = useState(true);

  const { history } = useReactRouter();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getUserExams = async () => {
    const {
      data: { user: userData, exams: examsData },
    } = await api.get("/exams/client/");
    setUser(userData);
    setExams(examsData);
  };

  const savePass = async () => {
    const { password, confirmPass } = pass;
    if (password !== confirmPass) {
      setMessage("As senhas digitas devem ser iguais!");
      setHidden(false);
      window.setTimeout(() => {
        setHidden(true);
      }, 4000);
      return;
    }

    const { response } = await api.post("/users/reset-password", {
      password: pass.password,
    });

    if (response) {
      setMessage(response.data.message);
      setHidden(false);
      window.setTimeout(() => {
        setHidden(true);
      }, 4000);
      return;
    }

    handleClose();
  };

  useEffect(() => {
    getUserExams();
  }, []);

  const modal = () => {
    return (
      <>
        <Modal show={show} onHide={handleClose}>
          <DefaultAlert message={message} hidden={hidden} variant="danger" />
          <Modal.Header closeButton>
            <Modal.Title>Redefinição de senha</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Col className="pr-1" className="">
              <Form.Group>
                <Form.Control
                  placeholder="Digite sua nova senha"
                  autoComplete="off"
                  onChange={(e) => onChangeFunc(e, pass, setPass)}
                  name="password"
                  type="password"
                ></Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Control
                  placeholder="Digite sua nova senha novamente"
                  autoComplete="off"
                  onChange={(e) => onChangeFunc(e, pass, setPass)}
                  name="confirmPass"
                  type="password"
                ></Form.Control>
              </Form.Group>
            </Col>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fechar
            </Button>
            <Button variant="secondary" onClick={savePass}>
              Salvar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  if (exams.length > 0) {
    return (
      <div className="container-list">
        <div className="container-listExams">
          <div className="div-menu">
            <aside className="menu">
              <img src={Logo} width={150} height={100} alt="logo" />
              <div>
                <a
                  onClick={() => history.push("/login")}
                  style={{ color: "#677798", margin: 10 }}
                >
                  Logout
                </a>

                <a
                  onClick={handleShow}
                  style={{ color: "#677798", margin: 10 }}
                >
                  Redefinir Senha
                </a>
              </div>
            </aside>
          </div>
          <div className="container-main">
            <header className="header">
              <ul className="listClient">
                <li style={{ color: "black" }}>{user.name}</li>
                <li>{user.complements.other_name}</li>
                <li>
                  {" "}
                  {user.complements.specie} - {user.complements.race}
                </li>
                <li>
                  {user.complements.age} {user.complements.age_type}
                </li>
              </ul>
            </header>
            <main className="content-list">
              {modal()}
              {exams.map((exam) => (
                <div className="div-content">
                  <strong>Tipo de Exame:</strong>
                  <p>{exam.exam}</p>
                  <strong>Veterinário(a):</strong>
                  <p>{exam.veterinarian}</p>
                  <strong>Data do Exame:</strong>
                  <p>{moment(exam.created_at).format("DD/MM/YYYY")}</p>
                  <a
                    className="download-exam"
                    href={exam.url}
                    target="_blank"
                    style={{
                      background:
                        exam.status == "Concluído" ? "#2B8B6B" : "#677798",
                    }}
                  >
                    {exam.status === "Concluído" ? (
                      <span>Baixar Exame</span>
                    ) : (
                      <span>Pendente</span>
                    )}
                  </a>
                </div>
              ))}
            </main>
            <a
              onClick={() => {
                window.scrollTo(0, 0);
              }}
              className="back-to-top"
            >
              <i
                className="fas fa-arrow-up fa-2x"
                style={{ marginRight: 10 }}
              ></i>
            </a>
          </div>
        </div>
        <Footer />
      </div>
    );
  } else {
    return (
      <div className="container-list">
        <DefaultAlert message={message} hidden={hidden} variant="danger" />
        <div className="container-listExams">
          <div className="div-menu">
            <aside className="menu">
              <img src={Logo} width={150} height={100} alt="logo" />
              <div>
                <a
                  onClick={() => history.push("/login")}
                  style={{ color: "#677798", margin: 10 }}
                >
                  Logout
                </a>

                <a
                  onClick={handleShow}
                  style={{ color: "#677798", margin: 10 }}
                >
                  Redefinir Senha
                </a>
              </div>
            </aside>
          </div>
          <div className="container-main" style={{ alignSelf: "center" }}>
            <main className="content-list">
              {modal()}
              <h4 style={{ color: "#677798" }}>
                Este paciente ainda não possui exames.
              </h4>
            </main>
          </div>
        </div>
      </div>
    );
  }
}

export default TableList;
