import { Alert } from "bootstrap";
import api from "client/api";
import { onChangeFunc } from "helpers/onChangeFunc";
import React, { useEffect, useState } from "react";
import useReactRouter from "use-react-router";
import { Form } from "react-bootstrap";
import Loader from "react-loader-spinner";
import Footer from "components/Footer/Footer";
import qs from "qs";
import { useLocation } from "react-router";
import DefaultAlert from "../components/Alert/Alert";

function LoginView() {
  const pathsUS = [
    require("assets/img/rx1.jpeg").default,
    require("assets/img/rx2.jpeg").default,
    require("assets/img/rx4.jpeg").default,
  ];

  const pathsRX = [
    require("assets/img/us1.jpeg").default,
    require("assets/img/us2.jpeg").default,
    require("assets/img/us3.jpeg").default,
  ];
  const { history } = useReactRouter();
  const location = useLocation();

  const [user, setUser] = useState({
    username: "",
    password: "",
  });
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState("");
  const [hidden, setHidden] = useState(true);

  const codes = {
    403: "Faça o login novamente.",
    401: "Usuário ou senha inválidos.",
  };

  useEffect(() => {
    const error = qs.parse(location.search, { ignoreQueryPrefix: true });

    if (Object.keys(error).length > 0) {
      setMessage(codes[error.status]);
      setHidden(false);
      window.setTimeout(() => {
        setHidden(true);
      }, 4000);
    }
  }, []);

  const login = async () => {
    setLoader(true);
    const { status, data, error } = await api.post("/login", user);

    if (status !== 200) {
      <Alert variant="danger">{error.message}</Alert>;
      return;
    }
    window.localStorage.setItem("role", data.role);
    window.localStorage.setItem("username", data.name);
    window.localStorage.setItem("user_id", data.id);
    window.localStorage.setItem("token", data.token);

    if (data.role === "admin") {
      history.push("/admin/dashboard");
    } else if (data.role === "client") {
      history.push("/client");
    } else if (data.role === "clinic") {
      history.push("/clinic");
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      login();
    }
  };

  const renderButton = () => {
    if (loader) {
      return (
        <button className="btn btn-primary login-btn" onClick={login} disabled>
          <Loader type="Oval" color="#000" height={20} width={20} />
        </button>
      );
    }

    return (
      <button className="btn btn-primary login-btn" onClick={login}>
        Login
      </button>
    );
  };

  return (
    <>
      <div className="bg-light vh-100">
        <DefaultAlert message={message} hidden={hidden} variant="danger" />
        <div className="row d-flex justify-content-around flex-row align-items-center ">
          <div className="col-sm-12 d-flex justify-content-center flex-column align-items-center vh-90">
            <div>
              <img
                className="cdi-logo-div"
                alt="..."
                src={require("assets/img/cdi-logo.png").default}
              ></img>
            </div>
            <Form.Group>
              <Form.Control
                onKeyPress={handleKeyPress}
                type="text"
                placeholder="Usuário"
                name="username"
                className="border border-success login-input-border-color"
                onChange={(e) => onChangeFunc(e, user, setUser)}
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Control
                onKeyPress={handleKeyPress}
                type="password"
                placeholder="Senha"
                name="password"
                className="border border-primary login-input-border-color"
                onChange={(e) => onChangeFunc(e, user, setUser)}
              ></Form.Control>
            </Form.Group>
            <Form.Group>{renderButton()}</Form.Group>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default LoginView;
