import react, { useEffect, useState } from "react";

export const Paginator = ({
  page: currentPage,
  pageTotal,
  search,
  listFunc,
}) => {
  const [pages, setPages] = useState([1, 2, 3]);
  const pagesCalculator = () => {
    if (pageTotal > 2) {
      if (+currentPage === 1) {
        setPages([1, 2, 3]);
      } else if (+currentPage === +pageTotal) {
        setPages([+currentPage - 2, +currentPage - 1, +currentPage]);
      } else {
        setPages([+currentPage - 1, +currentPage, +currentPage + 1]);
      }
    } else {
      if (pageTotal === 2) {
        setPages([1, 2]);
      } else {
        setPages([1]);
      }
    }
  };

  useEffect(() => {
    pagesCalculator();
  }, [currentPage, pageTotal]);

  return (
    <nav aria-label="...">
      <ul className="pagination">
        <li className={+currentPage === 1 ? "page-item disabled" : "page-item"}>
          <a
            onClick={() => listFunc(+currentPage - 1, search)}
            className="page-link"
            tabindex="-1"
          >
            Anterior
          </a>
        </li>
        {pages.map((page) => {
          return (
            <li
              className={
                page === +currentPage ? "page-item active" : "page-item"
              }
            >
              <a onClick={() => listFunc(page, search)} className="page-link">
                {page}
              </a>
            </li>
          );
        })}
        <li
          className={
            +currentPage === pageTotal ? "page-item disabled" : "page-item"
          }
        >
          <a
            className="page-link"
            onClick={() => listFunc(+currentPage + 1, search)}
          >
            Próximo
          </a>
        </li>
      </ul>
    </nav>
  );
};
