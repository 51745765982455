import api from "client/api";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useReactRouter from "use-react-router";
import { Card, Table, Container, Row, Col, Button } from "react-bootstrap";
import * as moment from "moment";
import PreLoader from "../components/Loader/Loader";
import DefaultAlert from "../components/Alert/Alert";

function TableList() {
  const [user, setUser] = useState([]);
  const [exams, setExams] = useState([]);
  const [preLoad, setPreLoad] = useState(true);
  const [message, setMessage] = useState("");
  const [hidden, setHidden] = useState(true);

  const { user_id } = useParams();
  const { history } = useReactRouter();

  const getUserExams = async () => {
    setPreLoad(true);
    const {
      data: { user: userData, exams: examsData },
      response,
    } = await api.get("/exams/user/" + user_id);

    if (response) {
      setMessage(response.data.message);
      setHidden(false);
      window.setTimeout(() => {
        setPreLoad(false);
        setHidden(true);
      }, 4000);
      return;
    }

    setPreLoad(false);
    setUser(userData);
    setExams(examsData);
  };

  useEffect(() => {
    getUserExams();
  }, []);

  const deleteExam = async (id) => {
    const { response } = await api.delete(`/exams/${id}`);
    if (response) {
      setMessage(response.data.message);
      setHidden(false);
      window.setTimeout(() => {
        setHidden(true);
      }, 4000);
      return;
    }

    await getUserExams();
  };

  const renderLink = (url) => {
    if (url) {
      return (
        <a href={url} target="_blank">
          <Button className="btn-fill pull-right" variant="info">
            <i class="fas fa-file-download"></i>
          </Button>{" "}
        </a>
      );
    }

    return (
      <>
        <Button
          className="btn-fill pull-right"
          variant="default"
          disabled="true"
        >
          <i class="fas fa-file-download"></i>
        </Button>{" "}
      </>
    );
  };

  const renderTabel = () => {
    if (exams.length > 0) {
      return (
        <Table className="table-hover table-striped">
          <thead>
            <tr>
              <th className="border-0">Paciente</th>
              <th className="border-0">Status</th>
              <th className="border-0">Tipo do exame</th>
              <th className="border-0">veterinário</th>
              <th className="border-0">Dt. criação</th>
              <th className="border-0"></th>
            </tr>
          </thead>
          <tbody>
            {exams.map((exam) => (
              <tr>
                <td>{exam.name}</td>
                <td>
                  <div
                    className={
                      exam.status == "Concluído"
                        ? "status status-done"
                        : "status status-pending"
                    }
                  >
                    {exam.status}
                  </div>
                </td>
                <td>{exam.exam}</td>
                <td>{exam.veterinarian}</td>
                <td>{moment(exam.created_at).format("DD/MM/YYYY")}</td>
                <td>
                  {renderLink(exam.url)}
                  <Button
                    className="btn-fill pull-right"
                    variant="primary"
                    onClick={() =>
                      history.push(
                        `/admin/exam/view/${exam.exam_id}/${user_id}`
                      )
                    }
                  >
                    <i class="fas fa-eye"></i>
                  </Button>{" "}
                  <Button
                    className="btn-fill pull-right"
                    variant="warning"
                    onClick={() =>
                      history.push(
                        `/admin/exam/edit/${exam.exam_id}/${user_id}`
                      )
                    }
                  >
                    <i class="fas fa-edit"></i>
                  </Button>{" "}
                  <Button
                    className="btn-fill pull-right"
                    variant="success"
                    onClick={() =>
                      history.push(
                        `/admin/exam/upload/${exam.exam_id}/${user_id}`
                      )
                    }
                  >
                    <i class="fas fa-file-upload"></i>
                  </Button>{" "}
                  <Button
                    className="btn-fill pull-right"
                    variant="danger"
                    onClick={() => deleteExam(exam.exam_id)}
                  >
                    <i class="fas fa-trash"></i>
                  </Button>{" "}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    }

    return (
      <>
        <hr></hr>
        <div className="d-flex justify-content-center">
          Esse paciente ainda não possui exames cadastrados
        </div>
      </>
    );
  };

  return (
    <>
      <Container fluid>
        <PreLoader hidden={!preLoad} />
        <DefaultAlert message={message} hidden={hidden} variant="danger" />
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header className="d-flex justify-content-between">
                <Col md="9" className="p-0">
                  <Card.Title as="h4">
                    Paciente {user.name} - Lista de Exames
                  </Card.Title>
                  <p className="card-category">{user.rx_code || ""}</p>
                </Col>
                <Col
                  md="3"
                  className="d-flex justify-content-center align-items-center"
                >
                  <a
                    href=""
                    onClick={() =>
                      history.push(`/admin/exam/create/${user_id}`)
                    }
                  >
                    <Button className="btn-fill pull-right" variant="info">
                      <i class="fas fa-plus-square"></i>
                    </Button>
                  </a>
                </Col>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                {renderTabel()}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default TableList;
