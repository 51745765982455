import React, { Component } from "react";
import { Container } from "react-bootstrap";

class Footer extends Component {
  render() {
    return (
      <div className="cdi-footer d-flex align-items-center justify-content-center">
        <div>
          <a
            href="https://www.google.com/maps/place/R.+Benjamin+Constant,+1703+-+Centro,+Pelotas+-+RS,+96010-020/@-31.7788287,-52.3441821,18z/data=!3m1!4b1!4m6!3m5!1s0x9511b5eb03d3ed79:0x1f34b342b1bfe2a6!8m2!3d-31.778831!4d-52.3428946!16s%2Fg%2F11csg58lmc"
            target="_blank"
          >
            <i class="fas fa-map-marker-alt"></i> Pelotas, RS - Rua Benjamin
            Constant, 1703{" "}
          </a>
          <br></br>
          <i class="fab fa-whatsapp"></i> <i class="fas fa-phone"></i> (53)
          3303-1905 <br />
          <a href="https://www.instagram.com/cdi_imagemvet/" target="_blank">
            <i class="fab fa-instagram"></i> @cdi_imagemvet
          </a>
        </div>
      </div>
    );
  }
}

export default Footer;
