import api from "client/api";
import React, { useEffect, useState } from "react";
import useReactRouter from "use-react-router";
import * as moment from "moment";
import { Paginator } from "../helpers/paginator";
// react-bootstrap components
import {
  Card,
  Form,
  Table,
  Container,
  Row,
  Col,
  Button,
  Modal,
} from "react-bootstrap";
import { onChangeFunc } from "helpers/onChangeFunc";
import PreLoader from "../components/Loader/Loader";
import DefaultAlert from "../components/Alert/Alert";

function TableList() {
  const [clinics, setClinics] = useState([]);
  const [pageInfos, setPageInfos] = useState({ page: 1, pageTotal: 1 });
  const [search, setSearch] = useState({});
  const [access, setAccess] = useState({});
  const [show, setShow] = useState(false);
  const [dateValue, setDateValue] = useState({});
  const [preLoad, setPreLoad] = useState(true);
  const [message, setMessage] = useState("");
  const [hidden, setHidden] = useState(true);

  const { history } = useReactRouter();
  const getClinics = async (page = 1, searchTerm = "", date = {}) => {
    setPreLoad(true);
    let query = `page=${page}&search=${searchTerm}`;

    if (date || Object.entries(date).length > 1) {
      const end = moment(date.end).add(1, "d").format("YYYY-MM-DD");
      query = `${query}&date_start=${date.start}&date_end=${end}`;
    }

    const {
      data: { data, ...pageInfos },
      response,
    } = await api.get(`/users/clinics?${query}`);

    if (response) {
      setMessage(response.data.message);
      setHidden(false);
      window.setTimeout(() => {
        setPreLoad(false);
        setHidden(true);
      }, 4000);
      return;
    }

    setPreLoad(false);
    setPageInfos(pageInfos);
    setSearch(searchTerm);
    setClinics(data);
  };

  useEffect(() => {
    getClinics();
  }, []);

  const deleteClinic = async (id) => {
    const { response } = await api.delete(`/users/${id}`);
    if (response) {
      setMessage(response.data.message);
      setHidden(false);
      window.setTimeout(() => {
        setHidden(true);
      }, 4000);
      return;
    }

    await getClinics(pageInfos.page, search, dateValue);
  };

  const resetPass = async (user_id) => {
    const { data } = await api.put(`/user/client/${user_id}/reset`);
    setAccess({
      username: data.username,
      password: data.password,
      name: data.name,
    });
    handleShow();
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const modal = () => {
    return (
      <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              Senha da clinica {access.name} atualizada com sucesso!
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Usuário: {access.username}</p>
            <p>Senha: {access.password}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  return (
    <>
      <Container fluid>
        <DefaultAlert message={message} hidden={hidden} variant="danger" />
        <PreLoader hidden={!preLoad} />
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Row className="d-flex justify-content-between">
                  <Col sm="9">
                    <Card.Title as="h4">Lista de Clinicas</Card.Title>
                  </Col>
                  <Col
                    sm="3"
                    className="d-flex justify-content-center align-items-center"
                  >
                    <a
                      href=""
                      onClick={() => history.push(`/admin/clinic/create`)}
                    >
                      <Button className="btn-fill pull-right" variant="info">
                        <i class="fas fa-plus-square"></i>
                      </Button>
                    </a>
                  </Col>
                </Row>
                <Row className="d-flex align-items-center">
                  <Col md="3" sm="5">
                    <Form.Group>
                      <Form.Label>Buscar</Form.Label>
                      <Form.Control
                        placeholder="Buscar"
                        onChange={(e) =>
                          getClinics(pageInfos.page, e.target.value)
                        }
                        name="search"
                        type="text"
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md="3" sm="3">
                    <Form.Group>
                      <Form.Label>Data Inicial</Form.Label>
                      <Form.Control
                        class="form-control"
                        onChange={(e) =>
                          onChangeFunc(e, dateValue, setDateValue)
                        }
                        type="date"
                        name="start"
                        value={dateValue.start}
                        id="example-date-input"
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md="3" sm="3">
                    <Form.Group>
                      <Form.Label>Data Final</Form.Label>
                      <Form.Control
                        class="form-control"
                        onChange={(e) =>
                          onChangeFunc(e, dateValue, setDateValue)
                        }
                        name="end"
                        type="date"
                        value={dateValue.end}
                        id="example-date-input"
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md="2" sm="2">
                    <Button
                      className="btn-fill pull-right"
                      variant="success"
                      size="md"
                      onClick={() =>
                        getClinics(pageInfos.page, search, dateValue)
                      }
                    >
                      <i class="fas fa-search"></i>
                    </Button>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table className="table-hover table-striped">
                  <thead>
                    <tr>
                      <th className="border-0"></th>
                      <th className="border-0">Clinica</th>
                      <th className="border-0">Contato</th>
                      <th className="border-0">Dt. Criação</th>
                      <th className="border-0"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {modal()}
                    {clinics.map((client, key) => (
                      <tr>
                        <td>{key + 1}</td>
                        <td>{client.name}</td>
                        <td>{client.contact}</td>
                        <td>
                          {moment(client.created_at).format("DD/MM/YYYY")}
                        </td>
                        <td>
                          <Button
                            className="btn-fill pull-right"
                            variant="primary"
                            onClick={() =>
                              history.push(`/admin/clinic/view/${client.id}`)
                            }
                          >
                            <i class="fas fa-eye"></i>
                          </Button>{" "}
                          <Button
                            className="btn-fill pull-right"
                            variant="warning"
                            onClick={() =>
                              history.push(`/admin/clinic/edit/${client.id}`)
                            }
                          >
                            <i class="fas fa-edit"></i>
                          </Button>{" "}
                          <Button
                            className="btn-fill pull-right"
                            variant="default"
                            onClick={() => resetPass(client.id)}
                          >
                            <i class="fas fa-key"></i>
                          </Button>{" "}
                          <Button
                            className="btn-fill pull-right"
                            variant="danger"
                            onClick={() => deleteClinic(client.id)}
                          >
                            <i class="fas fa-trash"></i>
                          </Button>{" "}
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colspan="100%">
                        <div className="d-flex justify-content-center">
                          <Paginator
                            page={pageInfos.page}
                            pageTotal={pageInfos.pageTotal}
                            search={search}
                            listFunc={getClinics}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default TableList;
