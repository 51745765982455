import CreateUser from "views/CreateUser.js";
import EditUser from "views/EditUser.js";
import ListClients from "views/ListClients.js";
import ViewUser from "views/ViewUser.js";

const dashboardRoutes = [
  {
    path: "/client/create",
    component: CreateUser,
    layout: "/admin",
  },
  {
    path: "/client/edit/:user_id",
    component: EditUser,
    layout: "/admin",
  },
  {
    path: "/client/view/:user_id",
    component: ViewUser,
    layout: "/admin",
  },
  {
    path: "/client",
    component: ListClients,
    layout: "/admin",
  },
];

export default dashboardRoutes;
