import React from "react";
import ReactDOM from "react-dom";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";
import "./assets/css/demo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/css/main.css";
import "bootstrap-select";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import AdminLayout from "layouts/Admin.js";
import Login from "layouts/Login";
import Client from "layouts/external/Client.js";
import Clinic from "layouts/external/Clinic.js";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
      <Route path="/login" render={(props) => <Login {...props} />} />
      <Route path="/client" render={(props) => <Client {...props} />} />
      <Route path="/clinic" render={(props) => <Clinic {...props} />} />
      <Redirect from="/" to="/login" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
