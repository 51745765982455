import React, { useEffect, useState } from "react";
import ChartistGraph from "react-chartist";
import useReactRouter from "use-react-router";
import { Card, Table, Container, Row, Col, Button } from "react-bootstrap";
import api from "client/api";
import * as moment from "moment";
import PreLoader from "../components/Loader/Loader";

function Dashboard() {
  const [clients, setClients] = useState([]);
  const [exams, setExams] = useState([]);
  const [preLoad, setPreLoad] = useState(true);
  const [metrics, setMetrics] = useState({
    examsCountGruped: {
      "Raio X": "0",
      Ultrassonografia: "0",
    },
    examsCountGrupedPercent: {
      "Raio X": "0",
      Ultrassonografia: "0",
    },
  });

  const { history } = useReactRouter();

  const getClients = async () => {
    setPreLoad(true);
    const {
      data: { data },
    } = await api.get("/users/clients");
    setPreLoad(false);
    setClients(data);
  };

  const deleteClient = async (id) => {
    const { response } = await api.delete(`/users/${id}`);

    await getClients();
  };

  const getExams = async () => {
    const {
      data: { data },
    } = await api.get("/exams");
    setExams(data);
  };

  const getMetrics = async () => {
    const { data } = await api.get("/exams/metrics");
    setMetrics(data);
  };

  useEffect(() => {
    getClients();
    getExams();
    getMetrics();
  }, []);

  const renderLink = (url) => {
    if (url) {
      return (
        <a href={url} target="_blank">
          <Button className="btn-fill pull-right" variant="info">
            <i class="fas fa-file-download"></i>
          </Button>{" "}
        </a>
      );
    }

    return (
      <>
        <Button
          className="btn-fill pull-right"
          variant="default"
          disabled="true"
        >
          <i class="fas fa-file-download"></i>
        </Button>{" "}
      </>
    );
  };

  return (
    <>
      <Container fluid>
        <PreLoader hidden={!preLoad} />
        <Row>
          <Col lg="4" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <img
                        className="img-icon-dash"
                        alt="..."
                        src={require("assets/img/exame.png").default}
                      ></img>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <Card.Title as="h4">{metrics.total}</Card.Title>
                      <p className="card-category">Exames</p>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="fas fa-redo mr-1"></i>
                  Exames totais realizados
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col lg="4" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <img
                        className="img-icon-dash"
                        alt="..."
                        src={require("assets/img/raiox.png").default}
                      ></img>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <Card.Title as="h4">
                        {metrics.examsCountGruped["Raio X"] || 0}
                      </Card.Title>
                      <p className="card-category">Raio X</p>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="far fa-calendar-alt mr-1"></i>
                  Realizados no mês de {metrics.month}
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col lg="4" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <img
                        className="img-icon-dash"
                        alt="..."
                        src={require("assets/img/ultrassom.png").default}
                      ></img>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <Card.Title as="h4">
                        {metrics.examsCountGruped["Ultrassonografia"] || 0}
                      </Card.Title>
                      <p className="card-category">Ultrassons</p>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="far fa-calendar-alt mr-1"></i>
                  Realizados no mês de {metrics.month}
                </div>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header className="d-flex justify-content-between">
                <Col md="9" className="p-0">
                  <Card.Title as="h4">Lista de Pacientes</Card.Title>
                </Col>
                <Col
                  md="3"
                  className="d-flex justify-content-center align-items-center"
                >
                  <a
                    href=""
                    onClick={() => history.push(`/admin/client/create`)}
                  >
                    <Button className="btn-fill pull-right" variant="info">
                      <i class="fas fa-plus-square"></i>
                    </Button>
                  </a>
                </Col>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table className="table-hover table-striped">
                  <thead>
                    <tr>
                      <th className="border-0">Paciente</th>
                      <th className="border-0">Tutor</th>
                      <th className="border-0">Raça</th>
                      <th className="border-0">Espécie</th>
                      <th className="border-0">Dt. Criação</th>
                      <th className="border-0"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {clients.map((client) => (
                      <tr>
                        <td>{client.name}</td>
                        <td>{client.other_name}</td>
                        <td>{client.race}</td>
                        <td>{client.specie}</td>
                        <td>
                          {moment(client.created_at).format("DD/MM/YYYY")}
                        </td>
                        <td>
                          <Button
                            className="btn-fill pull-right"
                            variant="primary"
                            onClick={() =>
                              history.push(
                                `/admin/client/view/${client.user_id}`
                              )
                            }
                          >
                            <i class="fas fa-eye"></i>
                          </Button>{" "}
                          <Button
                            className="btn-fill pull-right"
                            variant="warning"
                            onClick={() =>
                              history.push(
                                `/admin/client/edit/${client.user_id}`
                              )
                            }
                          >
                            <i class="fas fa-edit"></i>
                          </Button>{" "}
                          <Button
                            className="btn-fill pull-right"
                            onClick={() =>
                              history.push(`/admin/exam/${client.user_id}`)
                            }
                            variant="default"
                          >
                            <i class="fas fa-file-pdf"></i>
                          </Button>{" "}
                          <Button
                            className="btn-fill pull-right"
                            variant="danger"
                            onClick={() => deleteClient(client.user_id)}
                          >
                            <i class="fas fa-trash"></i>
                          </Button>{" "}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <a href="#" onClick={() => history.push(`/admin/client/`)}>
                    Ver mais
                  </a>
                </div>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="8">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">Últimos exames</Card.Title>
                <p className="card-category">
                  Veja os últimos {exams.length} exames
                </p>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive">
                <Table className="table-hover table-striped">
                  <thead>
                    <tr>
                      <th className="border-0">Paciente</th>
                      <th className="border-0">Status</th>
                      <th className="border-0">Tipo do exame</th>
                      <th className="border-0">veterinário</th>
                      <th className="border-0">Exame</th>
                      <th className="border-0"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {exams.map((exam) => (
                      <tr>
                        <td>{exam.name}</td>
                        <td>
                          <div
                            className={
                              exam.status == "Concluído"
                                ? "status status-done"
                                : "status status-pending"
                            }
                          >
                            {exam.status}
                          </div>
                        </td>
                        <td>{exam.exam}</td>
                        <td>{exam.veterinarian}</td>
                        {renderLink(exam.url)}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>

              <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <a href="#" onClick={() => history.push(`/admin/exam/`)}>
                    Ver mais
                  </a>
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col md="4">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Exames mensais</Card.Title>
              </Card.Header>
              <Card.Body>
                <div
                  className="ct-chart ct-perfect-fourth"
                  id="chartPreferences"
                >
                  <ChartistGraph
                    data={{
                      labels: [
                        `${metrics.examsCountGrupedPercent["Raio X"] || 0}%`,
                        `${
                          metrics.examsCountGrupedPercent.Ultrassonografia || 0
                        }%`,
                      ],
                      series: [
                        metrics.examsCountGrupedPercent["Raio X"] || 0,
                        metrics.examsCountGrupedPercent.Ultrassonografia || 0,
                      ],
                    }}
                    type="Pie"
                  />
                </div>
                <div className="legend">
                  <i className="fas fa-circle text-danger"></i> Ultrasson
                  <i className="fas fa-circle text-info"></i> Raio X
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Dashboard;
